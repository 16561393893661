/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Llegamos al último tipo de controlador que vamos a ver, el que aúna los tres tipos de control que ya hemos visto. Como ya he explicado anteriormente el uso que le damos a los parámetros kp, Ti y Td, os recomiendo que volváis a esas explicaciones si tenéis dudas y os paso directamente ", React.createElement(_components.a, {
    href: "http://entramado.net/cursocontrol/ControlLearningToolbox/controlPID.m"
  }, "el archivo de la semana"), ", esta vez con la siguiente sintaxis:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"matlab\"><pre class=\"language-matlab\"><code class=\"language-matlab\"><span class=\"token function\">controlPID</span><span class=\"token punctuation\">(</span>kp<span class=\"token punctuation\">,</span> Ti<span class=\"token punctuation\">,</span> Td<span class=\"token punctuation\">)</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Ahora las combinaciones posibles son enormes y tenemos una gran libertad de elección, pero os dejo a vosotros que experimentéis y os paso una imagen de muestra, a ver si conseguís algo parecido."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 560px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/d9de50e80e5852acacb44c4baaf8cb0a/b06ae/ControlPID2_10_05.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 77.21518987341771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVQoz51S2Y7DIAzs/39i33oknAZyEAI1eJUQpdl2d6WuHywfGsZjfCqlEJGUknMOAPe7FMJIKZWSUkohhNZarYkxSx0AlFLDMBDRqYKbtu28s0OAbnR+Us6bYYJ+0p03fYBu0s4v3o6mD1w7DfAEW2vmOU0TEWUiqsX3IOelm1Ky1i7g2uCceR9DoFKwlJJzLqu9BIhIRPM8G2M28KpZdF1EpFLyH+DKHGPcwGupKCWc+xyMiN57KTdwzp+MXTehten7tK/kg4XljNdr41wkWt7OOVf/HiAuc4UQvjEr9V9mxIW561KNj1L39Kj5lZkxGMe0M/zGXH1KaQPnvOS3m2hbBgCMMWstY0xrDQCcc2ttvXxjTNu29dTHcXweCdH2cFW1f4lzbp+ciB6PBx3s1DTN5XIBgPP5PM/zsYeIMcYK+9G+APD/ayAw/7AbAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Ejemplo de sistema controlado con un PID.\"\n        title=\"Ejemplo de sistema controlado con un PID.\"\n        src=\"/static/d9de50e80e5852acacb44c4baaf8cb0a/b06ae/ControlPID2_10_05.png\"\n        srcset=\"/static/d9de50e80e5852acacb44c4baaf8cb0a/c26ae/ControlPID2_10_05.png 158w,\n/static/d9de50e80e5852acacb44c4baaf8cb0a/6bdcf/ControlPID2_10_05.png 315w,\n/static/d9de50e80e5852acacb44c4baaf8cb0a/b06ae/ControlPID2_10_05.png 560w\"\n        sizes=\"(max-width: 560px) 100vw, 560px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
